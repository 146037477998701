import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import postsReducer from "./postsReducer";
import generalReducer from "./generalReducer";
import eventsReducer from "./eventsReducer";
import usersReducer from "./usersReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  postsReducer,
  generalReducer,
  eventsReducer,
  usersReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>;
