import React from "react";
import TextLink, { TextLinkProps } from "./TextLink";

interface AppBarMenuTextLinkProps {
  to: TextLinkProps["to"];
  text: TextLinkProps["text"];
}

const AppBarMenuTextLink: React.FC<AppBarMenuTextLinkProps> = ({ ...rest }) => {
  return (
    <TextLink fontSize={13} fontWeight="bold" letterSpacing={1} {...rest} />
  );
};

export default AppBarMenuTextLink;
