import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import CacheBuster from "react-cache-buster";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import TagManager from "react-gtm-module";

import PersistenceAuthProvider from "./PersistenceAuthProvider";
import maTheme from "./theme";
import Routes from "./routes/Routes";
import { AppStateType } from "./redux/reducers";
import { ThemeInitialStateType } from "./redux/reducers/themeReducer";
import { version } from "../package.json";

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId:
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_PROD_GTM_ID
      : process.env.REACT_APP_DEV_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

function App({ theme }: { theme: ThemeInitialStateType }) {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <React.Fragment>
        <Helmet titleTemplate="%s | InContact" defaultTitle="InContact" />
        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={maTheme(theme.currentTheme)}>
              <ThemeProvider theme={maTheme(theme.currentTheme)}>
                <PersistenceAuthProvider>
                  <Routes />
                </PersistenceAuthProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </React.Fragment>
    </CacheBuster>
  );
}

export default connect((store: AppStateType) => ({
  theme: store.themeReducer,
}))(App);
