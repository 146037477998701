import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";

import reduxStore from "../redux/store";
import {
  persistenceEnableFailed,
  persistenceEnableSucceeded,
} from "../redux/actions/generalActions";

const prodConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
  appId: process.env.REACT_APP_PROD_APP_ID,
};

const devConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
};

const config = process.env.REACT_APP_ENV === "prod" ? prodConfig : devConfig;

class Firebase {
  private static instance: Firebase;

  private auth: app.auth.Auth;
  private firestore: app.firestore.Firestore;
  private storage: app.storage.Storage;
  private analytics: app.analytics.Analytics;
  private functions: app.functions.Functions;

  constructor() {
    console.log("Firebase initialized");
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();
    this.analytics = app.analytics();
    this.functions = app.functions();
    this.firestore
      .enablePersistence()
      .then(() => {
        reduxStore.dispatch(persistenceEnableSucceeded());
      })
      .catch((err) => {
        reduxStore.dispatch(persistenceEnableFailed(err.code));
        if (err.code === "failed-precondition") {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
        } else if (err.code === "unimplemented") {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
        }
      });
    this.storage = app.storage();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new Firebase();
    }

    return this.instance;
  }

  getAnalytics = () => {
    return this.analytics;
  };

  getFunctions = () => {
    return this.functions;
  };

  signUpUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithEmailAndPassword = (
    email: string,
    password: string,
    withPersistence: boolean
  ) => {
    return new Promise<app.auth.UserCredential>((resolve, reject) => {
      const persistence = withPersistence
        ? app.auth.Auth.Persistence.LOCAL
        : app.auth.Auth.Persistence.NONE;

      this.auth
        .setPersistence(persistence)
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(
            `Error with Error Code ${errorCode} while setting firebase authentication persistence to local storage: ${errorMessage}`
          );
        })
        .finally(() => {
          // Existing and future Auth states are now persisted in the local storage.
          this.auth
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
              resolve(user);
            })
            .catch((error) => {
              reject(error);
            });
        });
    });
  };

  sendPasswordResetEmail = (email: string) =>
    this.auth.sendPasswordResetEmail(email);

  signOut = () => this.auth.signOut();

  onAuthStateChangedListener = (
    callback: (authUser: app.User | null) => any
  ): app.Unsubscribe => {
    return this.auth.onAuthStateChanged(callback);
  };

  createUserInDatabase = (
    uid: string,
    name: string,
    email: string,
    isAdmin: boolean = false
  ) =>
    this.firestore.collection("users").doc(uid).set({
      name,
      email,
      isAdmin,
    });

  user = (uid: string) => this.firestore.collection("users").doc(uid).get();

  unregisteredUser = (uid: string) =>
    this.firestore.collection("unregisteredUsers").doc(uid).get();

  allUnregisteredUsers = () =>
    this.firestore
      .collection("unregisteredUsers")
      .orderBy("creationDate", "asc")
      .get();

  searchUnregisteredUsersWithEmail = (email: string) =>
    this.firestore
      .collection("unregisteredUsers")
      .where("email", "==", email)
      .get();

  searchUnregisteredUsersWithPhone = (phone: string) =>
    this.firestore
      .collection("unregisteredUsers")
      .where("phone", "==", phone)
      .get();

  searchUnregisteredUsersWithNamePhoneAndEmail = (
    name: string,
    email: string,
    phone: string
  ) =>
    this.firestore
      .collection("unregisteredUsers")
      .where("name", "==", name)
      .where("email", "==", email)
      .where("phone", "==", phone)
      .get();

  addToUnregisteredUsers = (userDataToBeAdded: any) =>
    this.firestore.collection("unregisteredUsers").add(userDataToBeAdded);

  updateUnregisteredUser = (userDataToBeUpdated: any, userId: string) =>
    this.firestore
      .collection("unregisteredUsers")
      .doc(userId)
      .update(userDataToBeUpdated);

  deleteUnregisteredUser = (userIdToBeDeleted: string) =>
    this.firestore
      .collection("unregisteredUsers")
      .doc(userIdToBeDeleted)
      .delete();

  createPost = (postDataToBeAdded: any) =>
    this.firestore.collection("posts").add(postDataToBeAdded);

  updatePost = (postDataToBeUpdated: any, postId: string) =>
    this.firestore.collection("posts").doc(postId).update(postDataToBeUpdated);

  deletePost = (postIdToBeDeleted: string) =>
    this.firestore.collection("posts").doc(postIdToBeDeleted).delete();

  postCategories = () => this.firestore.collection("postCategories").get();

  postsFilteredByPostedByUID = (postedByUID: string) =>
    this.firestore
      .collection("posts")
      .where("postedByUID", "==", postedByUID)
      .orderBy("creationDate", "desc")
      .get();

  event = (eventId: string) =>
    this.firestore.collection("events").doc(eventId).get();

  eventPhotos = (eventId: string) =>
    this.firestore.collection("events").doc(eventId).collection("photos").get();

  events = () =>
    this.firestore.collection("events").orderBy("eventStartDate", "desc").get();

  createEvent = (eventDataToBeAdded: any) =>
    this.firestore.collection("events").add(eventDataToBeAdded);

  updateEvent = (eventId: string, data: app.firestore.UpdateData) =>
    this.firestore.collection("events").doc(eventId).update(data);

  updateEventPhotos = (
    eventId: string,
    mapOfPhotosDataForServer: { [id: string]: any }
  ) => {
    const batch = this.firestore.batch();

    const eventPhotosRef = this.firestore
      .collection("events")
      .doc(eventId)
      .collection("photos");

    Object.entries(mapOfPhotosDataForServer).forEach(([key, value], index) => {
      const photoDocumentRef = eventPhotosRef.doc(key);
      batch.set(photoDocumentRef, value, { merge: true });
    });

    // Commit the batch
    return batch.commit();
  };

  deleteEvent = (eventIdToBeDeleted: string) =>
    this.firestore.collection("events").doc(eventIdToBeDeleted).delete();

  deleteEventPhoto = (eventId: string, eventPhotoId: string) =>
    this.firestore
      .collection("events")
      .doc(eventId)
      .collection("photos")
      .doc(eventPhotoId)
      .delete();

  createPastEventPhoto = (pastEventPhotoToBeAdded: any) =>
    this.firestore.collection("pastEventPhotos").add(pastEventPhotoToBeAdded);

  uploadFile = (
    storageDirectoryPath: string,
    filename: string,
    fileData: Blob,
    nextOrObserver?:
      | app.storage.StorageObserver<app.storage.UploadTaskSnapshot>
      | null
      | ((snapshot: app.storage.UploadTaskSnapshot) => any),
    error?: ((error: app.storage.FirebaseStorageError) => any) | null,
    completeCallback?:
      | ((fileDownloadURL: string, fileRef: app.storage.Reference) => void)
      | null
  ): app.storage.UploadTask => {
    // Points to the root reference
    var storageRef = this.storage.ref();

    // Points to the given directory path
    var directoryRef = storageRef.child(storageDirectoryPath);

    var fileRef = directoryRef.child(filename);

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = fileRef.put(fileData);

    uploadTask.on(
      app.storage.TaskEvent.STATE_CHANGED,
      nextOrObserver,
      error,
      () => {
        if (completeCallback) {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            completeCallback(downloadURL, uploadTask.snapshot.ref);
          });
        }
      }
    );

    return uploadTask;
  };

  deleteFile = (filePath: string): Promise<any> => {
    // Points to the root reference
    var storageRef = this.storage.ref();

    var fileRef = storageRef.child(filePath);

    return fileRef.delete();
  };

  getListOfAllFilesInFolder = (storageDirectoryPath: string) => {
    const storageRef = this.storage.ref();

    // Points to the given directory path
    const directoryRef = storageRef.child(storageDirectoryPath);

    return directoryRef.listAll();
  };

  addNewFeedbacks = (feedbackDataToBeAdded: any) =>
    this.firestore.collection("feedbacks").add(feedbackDataToBeAdded);

  interestRegistrationFormDataForEvent = (eventId: string) =>
    this.firestore
      .collection("interestRegistrationFormEntriesPerEvent")
      .doc(eventId)
      .get();

  interestRegistrationFormDataEntriesForEvent = (eventId: string) =>
    this.firestore
      .collection("interestRegistrationFormEntriesPerEvent")
      .doc(eventId)
      .collection("entries")
      .get();

  submitNewEntryForInterestRegistrationFormForEvent = (
    eventId: string,
    formEntryData: any
  ) =>
    this.firestore
      .collection("interestRegistrationFormEntriesPerEvent")
      .doc(eventId)
      .collection("entries")
      .add(formEntryData);

  addListenerUponSingleDocument = (
    collectionId: string,
    documentId: string,
    snapshotListenerOptions: app.firestore.SnapshotListenOptions,
    onNext: (
      snapshot: app.firestore.DocumentSnapshot<app.firestore.DocumentData>
    ) => void,

    onError?: ((error: app.firestore.FirestoreError) => void) | undefined
  ) =>
    this.firestore
      .collection(collectionId)
      .doc(documentId)
      .onSnapshot(snapshotListenerOptions, onNext, onError);

  addListenerUponSingleCollection = (
    collectionId: string,
    snapshotListenerOptions: app.firestore.SnapshotListenOptions,
    onNext: (
      snapshot: app.firestore.QuerySnapshot<app.firestore.DocumentData>
    ) => void,

    onError?: ((error: app.firestore.FirestoreError) => void) | undefined
  ) =>
    this.firestore
      .collection(collectionId)
      .onSnapshot(snapshotListenerOptions, onNext, onError);
}

export default Firebase;
