import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { AppStateType } from "../redux/reducers";

interface AdminAuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AdminAuthGuard({ children }: AdminAuthGuardType) {
  const auth = useSelector((state: AppStateType) => state.authReducer);

  if (!auth.loggedInUser?.isAdmin) {
    return <Redirect to="/auth/sign-in" />;
  }

  return children;
}

export default AdminAuthGuard;
