import { UserType, UnregisteredUserType } from "../../types/users";
import * as types from "../../constants";

export type UsersStateType = {
  adminAllUnregisteredUsers: UnregisteredUserType[];
  adminAllUnregisteredUsersIsLoading: boolean;
  adminAllUnregisteredUsersError: string;
};

export default function reducer(
  state: UsersStateType = {
    adminAllUnregisteredUsers: [],
    adminAllUnregisteredUsersIsLoading: false,
    adminAllUnregisteredUsersError: "",
  },
  actions: {
    type: string;
    unregisteredUsers?: UnregisteredUserType[];
    error?: string;
    unregisteredUserIdToBeDeleted?: string;
  }
): UsersStateType {
  switch (actions.type) {
    case types.USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_REQUEST: {
      return { ...state, adminAllUnregisteredUsersIsLoading: true };
    }

    case types.USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_SUCCESS: {
      return {
        ...state,
        adminAllUnregisteredUsersIsLoading: false,
        adminAllUnregisteredUsersError: "",
        adminAllUnregisteredUsers: actions.unregisteredUsers
          ? actions.unregisteredUsers
          : [],
      };
    }

    case types.USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_FAILURE: {
      return {
        ...state,
        adminAllUnregisteredUsersIsLoading: false,
        adminAllUnregisteredUsersError: actions.error
          ? actions.error
          : "Something went wrong while fetching events.",
      };
    }

    case types.USERS_ADMIN_EDIT_UNREGISTERED_USER_DELETE_USER: {
      return {
        ...state,
        adminAllUnregisteredUsers: state.adminAllUnregisteredUsers.filter(
          (el) => el.id !== actions.unregisteredUserIdToBeDeleted
        ),
      };
    }

    default:
      return state;
  }
}
