import { PostType, PostCategoryType } from "../../types/posts";
import * as types from "../../constants";

export type PostsStateType = {
  adminMyPosts: PostType[];
  adminMyPostsIsLoading: boolean;
  adminMyPostsError: string;
  postCategories: PostCategoryType[] | null;
};

export default function reducer(
  state: PostsStateType = {
    adminMyPosts: [],
    adminMyPostsIsLoading: false,
    adminMyPostsError: "",
    postCategories: null,
  },
  actions: {
    type: string;
    posts?: PostType[];
    error?: string;
    postCategories?: PostCategoryType[];
    postIdToBeDeleted?: string;
  }
): PostsStateType {
  switch (actions.type) {
    case types.POSTS_ADMIN_FETCH_MY_POSTS_REQUEST: {
      return { ...state, adminMyPostsIsLoading: true };
    }

    case types.POSTS_ADMIN_FETCH_MY_POSTS_SUCCESS: {
      return {
        ...state,
        adminMyPostsIsLoading: false,
        adminMyPostsError: "",
        adminMyPosts: actions.posts ? actions.posts : [],
      };
    }

    case types.POSTS_ADMIN_FETCH_MY_POSTS_FAILURE: {
      return {
        ...state,
        adminMyPostsIsLoading: false,
        adminMyPostsError: actions.error
          ? actions.error
          : "Something went wrong while fetching posts.",
      };
    }

    case types.POSTS_CATEGORIES_FETCHED: {
      return {
        ...state,
        postCategories: actions.postCategories ? actions.postCategories : null,
      };
    }

    case types.POSTS_ADMIN_MY_POSTS_DELETE_POST: {
      return {
        ...state,
        adminMyPosts: state.adminMyPosts.filter(
          (el) => el.id !== actions.postIdToBeDeleted
        ),
      };
    }

    default:
      return state;
  }
}
