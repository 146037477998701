import firebase from "firebase/app";
import {
  UnregisteredUserType,
  UserNoteType,
  UserEnquiryType,
} from "../types/users";

const unregisteredUserFromFirebaseDoc = (
  doc:
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
) => {
  const notes: UserNoteType[] = doc.data()?.notes
    ? doc.data()?.notes.map((noteFromServer: any) => {
        const note: UserNoteType = {
          text: noteFromServer.text,
          addedByName: noteFromServer.addedByName,
          addedByUID: noteFromServer.addedByUID,
          source: noteFromServer.source,
          additionDate: noteFromServer.additionDate
            ? noteFromServer.additionDate.toDate()
            : null,
        };
        return note;
      })
    : [];

  const enquiries: UserEnquiryType[] = doc.data()?.enquiries
    ? doc.data()?.enquiries.map((enquiriesFromServer: any) => {
        const enquiry: UserEnquiryType = {
          text: enquiriesFromServer.text,
          source: enquiriesFromServer.source,
          creationDate: enquiriesFromServer.creationDate
            ? enquiriesFromServer.creationDate.toDate()
            : null,
        };
        return enquiry;
      })
    : [];

  const user: UnregisteredUserType = {
    id: doc.id,
    name: doc.data()?.name,
    email: doc.data()?.email,
    phone: doc.data()?.phone,
    secondaryEmail: doc.data()?.secondaryEmail,
    secondaryPhone: doc.data()?.secondaryPhone,
    source: doc.data()?.source,
    facebookId: doc.data()?.facebookId,
    instagramHandle: doc.data()?.instagramHandle,
    createdByUID: doc.data()?.createdByUID,
    lastUpdatedByUID: doc.data()?.lastUpdatedByUID,
    eventIdIncaseOfEventRegistration: doc.data()
      ?.eventIdIncaseOfEventRegistration,
    creationDate: doc.data()?.creationDate
      ? doc.data()?.creationDate.toDate()
      : null,
    updateDate: doc.data()?.updateDate ? doc.data()?.updateDate.toDate() : null,
    notes,
    enquiries,
    choseToUnsubscribe: doc.data()?.choseToUnsubscribe
      ? doc.data()?.choseToUnsubscribe
      : false,
    emailBlacklistedOnEmailPlatform: doc.data()?.emailBlacklistedOnEmailPlatform
      ? doc.data()?.emailBlacklistedOnEmailPlatform
      : false,
  };

  return user;
};

export { unregisteredUserFromFirebaseDoc };
