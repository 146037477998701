import * as types from "../../constants";
import firebase from "firebase";
import { AppDispatchType } from "../store";
import { SignUpType, ResetPasswordType } from "../../types/auth";
import {
  signUp as authSignUp,
  signOut as authSignOut,
  resetPassword as authResetPassword,
} from "../../services/authService";
import { UserType } from "../../types/users";

export function userSignInStarted() {
  return { type: types.AUTH_SIGN_IN_REQUEST };
}

export function userSignInFinished(authUser: firebase.User, user: UserType) {
  return { type: types.AUTH_SIGN_IN_SUCCESS, authUser, user };
}

export function userSignInFailed(error: string) {
  return { type: types.AUTH_SIGN_IN_FAILURE, error };
}

export function signUp(credentials: SignUpType) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response: any) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch: AppDispatchType) => {
    return authSignOut().finally(() => {
      dispatch({
        type: types.AUTH_SIGN_OUT,
      });
    });
  };
}

export function resetPassword(email: string) {
  return async (dispatch: AppDispatchType) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(email)
      .then((response: any) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
