import * as types from "../../constants";

export type GeneralStateType = {
  isPeristenceEnabled: boolean;
  errorCodeWhileEnablingPersistence: string | undefined;
};

export default function reducer(
  state: GeneralStateType = {
    isPeristenceEnabled: false,
    errorCodeWhileEnablingPersistence: undefined,
  },
  actions: {
    type: string;
    errorCodeWhileEnablingPersistence?: string;
  }
): GeneralStateType {
  switch (actions.type) {
    case types.GENERAL_PERSISTENCE_ENABLE_SUCCESS: {
      return { ...state, isPeristenceEnabled: true };
    }

    case types.GENERAL_PERSISTENCE_ENABLE_FAILURE: {
      return {
        ...state,
        isPeristenceEnabled: false,
        errorCodeWhileEnablingPersistence:
          actions.errorCodeWhileEnablingPersistence,
      };
    }

    default:
      return state;
  }
}
