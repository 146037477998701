import Firebase from "./firebase";
import { UserType, UnregisteredUserType, UserNoteType } from "../types/users";
import { UnregisteredUserFormValuesType } from "../types/forms";
import { GetInTouchFormValuesType } from "../types/forms";
import { EventParticipantType } from "../types/events";
import firebase from "firebase/app";
import { unregisteredUserFromFirebaseDoc } from "../utils/dataConversion";

export function fetchUser(uid: string) {
  return new Promise<UserType>((resolve, reject) => {
    Firebase.getInstance()
      .user(uid)
      .then((doc) => {
        if (doc.exists) {
          const user: UserType = {
            name: doc.data()?.name,
            email: doc.data()?.email,
            uid: doc.id,
            isAdmin: doc.data()?.isAdmin,
            adminRoles: doc.data()?.adminRoles,
          };

          resolve(user);
        } else {
          console.log(
            `No document found in users collection of database for UID ${uid}.`
          );

          reject(
            new Error(
              `No document found in users collection of database for UID ${uid}`
            )
          );
        }
      })
      .catch((error) => {
        console.log(
          `Error while fetching user info from database for UID ${uid}: ${error}`
        );

        reject(
          new Error(
            `Error while fetching user info from database for UID ${uid}: ${error}`
          )
        );
      });
  });
}

export function fetchUnregisteredUser(uid: string) {
  return new Promise<UnregisteredUserType>((resolve, reject) => {
    Firebase.getInstance()
      .unregisteredUser(uid)
      .then((doc) => {
        if (doc.exists) {
          const unregisteredUser: UnregisteredUserType = unregisteredUserFromFirebaseDoc(
            doc
          );

          resolve(unregisteredUser);
        } else {
          reject(
            new Error(
              `No document found in unregisteredUsers collection of database for UID ${uid}`
            )
          );
        }
      })
      .catch((error) => {
        reject(
          new Error(
            `Error while fetching unregisteredUsers info from database for UID ${uid}: ${error}`
          )
        );
      });
  });
}

export function searchUnregisteredUserWithEmail(email: string) {
  return new Promise<UnregisteredUserType[]>((resolve, reject) => {
    Firebase.getInstance()
      .searchUnregisteredUsersWithEmail(email.toLowerCase().trim())
      .then((querySnapshot) => {
        const searchResults: UnregisteredUserType[] = [];

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          const unregisteredUser: UnregisteredUserType = unregisteredUserFromFirebaseDoc(
            doc
          );
          searchResults.push(unregisteredUser);
        });

        resolve(searchResults);
      })
      .catch((error) => {
        reject(new Error(`${error}`));
      });
  });
}

export function adminFetchUnregisteredUsers() {
  return new Promise<UnregisteredUserType[]>((resolve, reject) => {
    Firebase.getInstance()
      .allUnregisteredUsers()
      .then(async (querySnapshot) => {
        const users: UnregisteredUserType[] = [];

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          const user = unregisteredUserFromFirebaseDoc(doc);
          users.push(user);
        });

        resolve(users);
      })
      .catch((error) => {
        reject(new Error(`${error}`));
      });
  });
}

export function adminDeleteUnregisteredUser(userIdToBeDeleted: string) {
  return new Promise<boolean>((resolve, reject) => {
    Firebase.getInstance()
      .deleteUnregisteredUser(userIdToBeDeleted)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(new Error(`${error}`));
      });
  });
}

export function searchUnregisteredUsersWithEventParticipantData({
  name,
  email,
  phone,
}: EventParticipantType) {
  return new Promise<UnregisteredUserType[]>((resolve, reject) => {
    Firebase.getInstance()
      .searchUnregisteredUsersWithNamePhoneAndEmail(name, email, phone)
      .then((querySnapshot) => {
        const arrayOfUnregisteredUsers: UnregisteredUserType[] = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          const unregisteredUser: UnregisteredUserType = unregisteredUserFromFirebaseDoc(
            doc
          );
          arrayOfUnregisteredUsers.push(unregisteredUser);
        });
        resolve(arrayOfUnregisteredUsers);
      })
      .catch((error) => {
        console.log(error);
        reject(
          new Error(
            `There was an error adding note while searching unregistered users with name: ${name}, email: ${email} and phone: ${phone} on the server. Please copy this error message to an administrator at incontact before closing this window. Error: ${error} `
          )
        );
      });
  });
}

export function addNoteToUnregisteredUser(
  userId: string,
  note: string,
  addedByName: string,
  addedByUID: string,
  source: "incontact_web_admin" | "incontact_event_registration"
) {
  return new Promise<void>((resolve, reject) => {
    const newNote = {
      text: note,
      addedByName,
      addedByUID,
      source,
      additionDate: firebase.firestore.Timestamp.now(),
    };

    Firebase.getInstance()
      .updateUnregisteredUser(
        {
          notes: firebase.firestore.FieldValue.arrayUnion(newNote),
        },
        userId
      )
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(
          new Error(
            `There was an error while adding note "${note}" to the unregistered user with id: ${userId} on the server. Please copy this error message to an administrator at incontact before closing this window. Error: ${error} `
          )
        );
      });
  });
}

export function getInTouch({
  name,
  email,
  phone,
  enquiry,
}: GetInTouchFormValuesType) {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // First Step: Search Unregistered users with email
      const trimmedAndLowerCasedEmail = email.trim().toLocaleLowerCase();

      const querySnapshot = await Firebase.getInstance().searchUnregisteredUsersWithEmail(
        trimmedAndLowerCasedEmail
      );

      const arrayOfUnregisteredUsersWithEmailAddress: UnregisteredUserType[] = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const unregisteredUser: UnregisteredUserType = unregisteredUserFromFirebaseDoc(
          doc
        );
        arrayOfUnregisteredUsersWithEmailAddress.push(unregisteredUser);
      });

      let existingUnregisteredUserWithEmailAddress:
        | UnregisteredUserType
        | undefined = undefined;
      if (arrayOfUnregisteredUsersWithEmailAddress.length === 1) {
        existingUnregisteredUserWithEmailAddress =
          arrayOfUnregisteredUsersWithEmailAddress[0];
      } else if (arrayOfUnregisteredUsersWithEmailAddress.length > 1) {
        // If there are more than 1 user found in the database with the email address, then log to LogRocket as this situation should not happen and show an error.
        // TODO: Log to LogRocket this situation which we dont want

        throw new Error();
      }

      // Second Step:
      // If there is any user with the given email, then update its 'choseToUnsubscribe' to false, add enquiry if there is and update its 'name' and 'phone'.
      // Else add a new user to unregistered users database.

      if (existingUnregisteredUserWithEmailAddress) {
        // Prepare data to be updated to Firestore Document on server.
        // Add Enquiry to user if it was filled by user.
        let updatedEnquires: any | undefined = undefined;
        if (enquiry) {
          updatedEnquires = firebase.firestore.FieldValue.arrayUnion({
            text: enquiry,
            source: "incontact_web_subscribe",
            creationDate: firebase.firestore.Timestamp.now(),
          });
        }
        const userDataToBeUpdatedOnSever = {
          choseToUnsubscribe: false,
          lastUpdatedByUID: "incontact_web_subscribe",
          updateDate: firebase.firestore.Timestamp.now(),
          name: name.toLowerCase(),
          ...(phone && { phone: phone }),
          ...(updatedEnquires && { enquiries: updatedEnquires }),
        };
        await Firebase.getInstance().updateUnregisteredUser(
          userDataToBeUpdatedOnSever,
          existingUnregisteredUserWithEmailAddress.id
        );

        // Third Step: Make a call to cloud function to update or create a contact on email system and also trigger a resumption of subscription email.
        const emailSystemUserChangedSubscriptionStatus = Firebase.getInstance()
          .getFunctions()
          .httpsCallable("emailSystemUserChangedSubscriptionStatus");

        const successFromCloudFunction = await emailSystemUserChangedSubscriptionStatus(
          {
            name,
            choseToUnsubscribe: false,
            email,
            isRegisteredUser: false, // TODO: Future - This has to become dynamic in the sense that it will have to seen which colleciton we got the result from.
            userId: existingUnregisteredUserWithEmailAddress.id,
          }
        );

        if (successFromCloudFunction) {
          console.log(
            "usersService:getInTouch -> Finished running cloud function emailSystemUserChangedSubscriptionStatus for updating contact on email system and triggering resumption of subscription email."
          );
        } else {
          console.log(
            "usersService:getInTouch -> Unexpected response from cloud function emailSystemUserChangedSubscriptionStatus for updating contact on email system and triggering resumption of subscription email."
          );
        }
      } else {
        // Prepare data to be added to Firestore Document on server.

        const userDataToBeAddedOnSever = dataForCreatingNewUnregisteredUserOnServer(
          {
            trimmedAndLowerCasedEmail,
            name,
            source: "incontact_web_subscribe",
            phone,
            enquiries: enquiry
              ? [
                  {
                    text: enquiry,
                    source: "incontact_web_subscribe",
                    creationDate: firebase.firestore.Timestamp.now(),
                  },
                ]
              : [],
          }
        );
        await Firebase.getInstance().addToUnregisteredUsers(
          userDataToBeAddedOnSever
        );
      }

      resolve();
    } catch (error) {
      const errorMessage = "Something is wrong behind the scenes.";
      reject(new Error(errorMessage));
    }
  });
}

export function unsubscribe(userInfo: {
  userId: string;
  name: string;
  email: string;
  isRegisteredUser: boolean;
}) {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // First Step: Change the value of choseToUnsubscribe in our database for this userId
      // Prepare data to be updated to Firestore Document on server.

      const { userId, name, email, isRegisteredUser } = userInfo;

      const userDataToBeUpdatedOnSever = {
        choseToUnsubscribe: true,
        lastUpdatedByUID: "incontact_web_subscribe",
        updateDate: firebase.firestore.Timestamp.now(),
      };
      await Firebase.getInstance().updateUnregisteredUser(
        userDataToBeUpdatedOnSever,
        userId
      );

      console.log(
        "usersService:unsubscribe -> choseToUnsubscribe updated in incontact DB."
      );

      // Second Step: Call the cloud function to update this change in the email system
      const emailSystemUserChangedSubscriptionStatus = Firebase.getInstance()
        .getFunctions()
        .httpsCallable("emailSystemUserChangedSubscriptionStatus");

      const successFromCloudFunction = await emailSystemUserChangedSubscriptionStatus(
        { name, choseToUnsubscribe: true, email, isRegisteredUser, userId }
      );

      if (successFromCloudFunction) {
        console.log(
          "usersService:unsubscribe -> Finished running cloud function emailSystemUserChangedSubscriptionStatus for updating contact on email system."
        );
      } else {
        console.log(
          "usersService:unsubscribe -> Unexpected response from cloud function emailSystemUserChangedSubscriptionStatus for updating contact on email system."
        );
      }

      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

export function adminCreateNewUnregisteredUser(
  {
    name,
    phone,
    email,
    facebookId,
    instagramHandle,
    notes,
    secondaryEmail,
    secondaryPhone,
  }: UnregisteredUserFormValuesType,
  userUIDCreatingTheUser: string,
  userNameCreatingTheUser: string
): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      // First Step: Search Unregistered users with email to see if a user already exists

      const trimmedAndLowerCasedEmail = email.trim().toLocaleLowerCase();
      const querySnapshot = await Firebase.getInstance().searchUnregisteredUsersWithEmail(
        trimmedAndLowerCasedEmail
      );

      const arrayOfUnregisteredUsersWithEmailAddress: UnregisteredUserType[] = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const unregisteredUser: UnregisteredUserType = unregisteredUserFromFirebaseDoc(
          doc
        );
        arrayOfUnregisteredUsersWithEmailAddress.push(unregisteredUser);
      });

      if (arrayOfUnregisteredUsersWithEmailAddress.length === 1) {
        throw new Error(
          `User with ${trimmedAndLowerCasedEmail} as their primary email address already exists.`
        );
      } else if (arrayOfUnregisteredUsersWithEmailAddress.length > 1) {
        // If there are more than 1 user found in the database with the email address, then log to LogRocket as this situation should not happen and show an error.
        // TODO: Log to LogRocket this situation which we dont want

        throw new Error(
          `There are more than one users with ${trimmedAndLowerCasedEmail} as their primary email address in the system. This is a bug in the system. Please report this to admin.`
        );
      }

      // Step: Add a new user to unregistered users database.

      // Prepare data to be added to Firestore Document on server.

      const dataForSubmissionOnServer = dataForCreatingNewUnregisteredUserOnServer(
        {
          trimmedAndLowerCasedEmail,
          name,
          source: "incontact_web_admin",
          phone,
          secondaryEmail,
          secondaryPhone,
          facebookId,
          instagramHandle,
          notes: extractNotesFromUnregisteredUserFormForServer(
            notes,
            userUIDCreatingTheUser,
            userNameCreatingTheUser,
            "incontact_web_admin"
          ),
          createdByUID: userUIDCreatingTheUser,
        }
      );

      await Firebase.getInstance().addToUnregisteredUsers(
        dataForSubmissionOnServer
      );

      resolve(true);
    } catch (error) {
      let errorMessage = "Something is wrong behind the scenes.";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      reject(new Error(errorMessage));
    }
  });
}

export function adminUpdateUnregisteredUser(
  {
    name,
    phone,
    facebookId,
    instagramHandle,
    notes,
    secondaryEmail,
    secondaryPhone,
  }: UnregisteredUserFormValuesType,
  userId: string,
  userUIDUpdatingTheUser: string,
  userNameUpdatingTheUser: string
) {
  return new Promise<boolean>((resolve, reject) => {
    // Prepare data to be updated to Firestore Document on server.

    const userDataToBeUpdatedOnSever = {
      name: name.trim().toLowerCase(),
      phone: phone.trim(),
      facebookId: facebookId.trim(),
      instagramHandle: instagramHandle.trim(),
      secondaryEmail: secondaryEmail.trim().toLowerCase(),
      secondaryPhone: secondaryPhone.trim(),
      notes: extractNotesFromUnregisteredUserFormForServer(
        notes,
        userUIDUpdatingTheUser,
        userNameUpdatingTheUser,
        "incontact_web_admin"
      ),
      lastUpdatedByUID: userUIDUpdatingTheUser,
      updateDate: firebase.firestore.Timestamp.now(),
    };

    Firebase.getInstance()
      .updateUnregisteredUser(userDataToBeUpdatedOnSever, userId)
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        let errorMessage = "Something is wrong behind the scenes.";
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        reject(new Error(errorMessage));
      });
  });
}

/* Local Helper Functions */

function dataForCreatingNewUnregisteredUserOnServer(inputData: {
  trimmedAndLowerCasedEmail: string;
  name: string;
  source:
    | "incontact_web_subscribe"
    | "incontact_web_admin"
    | "incontact_event_registration";

  phone?: string;
  secondaryEmail?: string;
  secondaryPhone?: string;
  facebookId?: string;
  instagramHandle?: string;
  eventIdIncaseOfEventRegistration?: string;
  notes?: any[];
  enquiries?: any[];
  createdByUID?: string;
}) {
  return {
    email: inputData.trimmedAndLowerCasedEmail,
    name: inputData.name.trim().toLowerCase(),
    source: inputData.source,
    phone: inputData.phone ? inputData.phone.trim() : "",
    secondaryEmail: inputData.secondaryEmail
      ? inputData.secondaryEmail.trim().toLowerCase()
      : "",
    secondaryPhone: inputData.secondaryPhone
      ? inputData.secondaryPhone.trim()
      : "",
    facebookId: inputData.facebookId ? inputData.facebookId.trim() : "",
    instagramHandle: inputData.instagramHandle
      ? inputData.instagramHandle.trim()
      : "",
    eventIdIncaseOfEventRegistration: inputData.eventIdIncaseOfEventRegistration
      ? inputData.eventIdIncaseOfEventRegistration
      : "",
    notes: inputData.notes ? inputData.notes : [],
    enquiries: inputData.enquiries ? inputData.enquiries : [],
    createdByUID: inputData.createdByUID ? inputData.createdByUID : "",
    lastUpdatedByUID: "",
    updateDate: null,
    creationDate: firebase.firestore.Timestamp.now(),
    choseToUnsubscribe: false,
  };
}

function extractNotesFromUnregisteredUserFormForServer(
  notesFromForm: { note: UserNoteType; isNewlyAddedInForm: Boolean }[],
  loggedInAppUserUID: string,
  loggedInAppUserName: string,
  source: string
) {
  const notes = notesFromForm.map((el) => {
    if (el.isNewlyAddedInForm) {
      const noteForServer = {
        text: el.note.text,
        addedByName: loggedInAppUserName,
        addedByUID: loggedInAppUserUID,
        additionDate: firebase.firestore.Timestamp.now(),
        source: source,
      };
      return noteForServer;
    } else {
      return el.note;
    }
  });

  return notes;
}
