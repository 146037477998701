import React from "react";
import { makeStyles, IconButton, Theme } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TelegramIcon from "@material-ui/icons/Telegram";
import {
  INCONTACT_FACEBOOK_URL,
  INCONTACT_INSTAGRAM_URL,
  INCONTACT_YOUTUBE_URL,
  INCONTACT_TELEGRAM_URL,
} from "../constants/urls";

const useStyles = makeStyles<Theme, SocialMediaProps>({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  icons: {
    fontSize: (props) => props.fontSize,
  },
});

interface SocialMediaProps {
  fontSize: number;
}

const SocialMedia: React.FC<SocialMediaProps> = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <IconButton
        aria-label="facebook"
        color="inherit"
        onClick={() => {
          window.open(INCONTACT_FACEBOOK_URL, "_blank");
        }}
      >
        <FacebookIcon className={classes.icons} />
      </IconButton>

      <IconButton
        aria-label="instagram"
        color="inherit"
        onClick={() => {
          window.open(INCONTACT_INSTAGRAM_URL, "_blank");
        }}
      >
        <InstagramIcon className={classes.icons} />
      </IconButton>

      <IconButton
        aria-label="youtube"
        color="inherit"
        onClick={() => {
          window.open(INCONTACT_YOUTUBE_URL, "_blank");
        }}
      >
        <YouTubeIcon className={classes.icons} />
      </IconButton>

      <IconButton
        aria-label="telegram"
        color="inherit"
        onClick={() => {
          window.open(INCONTACT_TELEGRAM_URL, "_blank");
        }}
      >
        <TelegramIcon className={classes.icons} />
      </IconButton>
    </div>
  );
};
export default SocialMedia;
