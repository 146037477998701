import {
  HOME_PAGE_URL,
  ABOUT_US_PAGE_URL,
  TOOLS_PAGE_URL,
  CONTACT_US_PAGE_URL,
  // EVENTS_UPCOMING_EVENTS_PAGE_URL,
  EVENTS_PAGE_URL,
  SUPPORT_US_PAGE_URL,
} from "./urls";

// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
  IC_HOMEPAGE: "IC_HOMEPAGE",
  PUBLIC_PAGES: "PUBLIC_PAGES",
};

// Auth Redux Action Types
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Users Redux Action Types
export const USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_REQUEST =
  "USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_REQUEST";
export const USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_SUCCESS =
  "USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_SUCCESS";
export const USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_FAILURE =
  "USERS_ADMIN_FETCH_ALL_UNREGISTERED_USERS_FAILURE";
export const USERS_ADMIN_EDIT_UNREGISTERED_USER_DELETE_USER =
  "USERS_ADMIN_EDIT_UNREGISTERED_USER_DELETE_USER";

// Posts Redux Action Types
export const POSTS_ADMIN_FETCH_MY_POSTS_REQUEST =
  "POSTS_ADMIN_FETCH_MY_POSTS_REQUEST";
export const POSTS_ADMIN_FETCH_MY_POSTS_SUCCESS =
  "POSTS_ADMIN_FETCH_MY_POSTS_SUCCESS";
export const POSTS_ADMIN_FETCH_MY_POSTS_FAILURE =
  "POSTS_ADMIN_FETCH_MY_POSTS_FAILURE";
export const POSTS_ADMIN_MY_POSTS_DELETE_POST =
  "POSTS_ADMIN_MY_POSTS_DELETE_POST";
export const POSTS_CATEGORIES_FETCHED = "POSTS_CATEGORIES_FETCHED";

// Events Redux Action Types
export const EVENTS_ADMIN_FETCH_ALL_EVENTS_REQUEST =
  "EVENTS_ADMIN_FETCH_ALL_EVENTS_REQUEST";
export const EVENTS_ADMIN_FETCH_ALL_EVENTS_SUCCESS =
  "EVENTS_ADMIN_FETCH_ALL_EVENTS_SUCCESS";
export const EVENTS_ADMIN_FETCH_ALL_EVENTS_FAILURE =
  "EVENTS_ADMIN_FETCH_ALL_EVENTS_FAILURE";

export const EVENTS_ADMIN_ALL_EVENTS_DELETE_EVENT =
  "EVENTS_ADMIN_ALL_EVENTS_DELETE_EVENT";
export const EVENTS_ADMIN_FETCH_PAST_EVENT_DATA_REQUEST =
  "EVENTS_ADMIN_FETCH_PAST_EVENT_DATA_REQUEST";
export const EVENTS_ADMIN_FETCH_PAST_EVENT_DATA_SUCCESS =
  "EVENTS_ADMIN_FETCH_PAST_EVENT_DATA_SUCCESS";
export const EVENTS_ADMIN_FETCH_PAST_EVENT_DATA_FAILURE =
  "EVENTS_ADMIN_FETCH_PAST_EVENT_DATA_FAILURE";

export const EVENTS_ADMIN_UPDATE_EVENT_POSTER_URL =
  "EVENTS_ADMIN_UPDATE_EVENT_POSTER_URL";

export const EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_REQUEST =
  "EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_REQUEST";
export const EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_SUCCESS =
  "EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_SUCCESS";
export const EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_FAILURE =
  "EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_FAILURE";
export const EVENTS_EVENT_REGISTRATION_DESK_ADD_PARTICIPANT_SUCCESS =
  "EVENTS_EVENT_REGISTRATION_DESK_ADD_PARTICIPANT_SUCCESS";
export const EVENTS_EVENT_REGISTRATION_DESK_REMOVE_PARTICIPANT_SUCCESS =
  "EVENTS_EVENT_REGISTRATION_DESK_REMOVE_PARTICIPANT_SUCCESS";
export const EVENTS_EVENT_REGISTRATION_DESK_EVENT_HAS_PENDING_WRITES_UPDATE =
  "EVENTS_EVENT_REGISTRATION_DESK_EVENT_HAS_PENDING_WRITES_UPDATE";
export const EVENTS_EVENT_REGISTRATION_DESK_ERROR_ADDING_PARTICIPANT_TO_EVENT_ON_SERVER =
  "EVENTS_EVENT_REGISTRATION_DESK_ERROR_ADDING_PARTICIPANT_TO_EVENT_ON_SERVER";
export const EVENTS_EVENT_REGISTRATION_DESK_ERROR_REMOVING_PARTICIPANT_FROM_EVENT_ON_SERVER =
  "EVENTS_EVENT_REGISTRATION_DESK_ERROR_REMOVING_PARTICIPANT_FROM_EVENT_ON_SERVER";

// General Redux Action Types
export const GENERAL_PERSISTENCE_ENABLE_SUCCESS =
  "GENERAL_PERSISTENCE_ENABLE_SUCCESS";
export const GENERAL_PERSISTENCE_ENABLE_FAILURE =
  "GENERAL_PERSISTENCE_ENABLE_FAILURE";

// Site Navigation Menu
export const PUBLIC_PAGE_SITE_NAVIGATION_FIXED_MENU_ITEMS = [
  { title: "Home", path: HOME_PAGE_URL },
  { title: "About Us", path: ABOUT_US_PAGE_URL },
  { title: "Events", path: EVENTS_PAGE_URL },
  { title: "Resources", path: TOOLS_PAGE_URL },
  { title: "Support Us", path: SUPPORT_US_PAGE_URL },
  // {
  //   title: "Events1",
  //   path: "",
  //   items: [
  //     { title: "Upcoming", path: "" },
  //     { title: "Past", path: "" },
  //   ],
  // },
  { title: "Contact Us", path: CONTACT_US_PAGE_URL },
];
