import firebase from "firebase";

import * as types from "../../constants";
import { UserType } from "../../types/users";

export type AuthType = {
  loggedInUser: UserType | undefined;
  loggedInAuthUser: firebase.User | undefined;
  loading: boolean;
  error: string;
};

export default function reducer(
  state: AuthType = {
    loggedInAuthUser: undefined,
    loggedInUser: undefined,
    loading: false,
    error: "",
  },
  actions: {
    type: string;
    user?: UserType;
    authUser?: firebase.User;
    error?: string;
  }
): AuthType {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.AUTH_SIGN_IN_SUCCESS: {
      return {
        ...state,
        loggedInAuthUser: actions.authUser,
        loggedInUser: actions.user,
        loading: false,
        error: "",
      };
    }

    case types.AUTH_SIGN_IN_FAILURE: {
      return {
        ...state,
        loggedInAuthUser: undefined,
        loggedInUser: undefined,
        loading: false,
        error: actions.error ? actions.error : "",
      };
    }

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        loggedInAuthUser: undefined,
        loggedInUser: undefined,
        loading: false,
        error: "",
      };

    default:
      return state;
  }
}
