import React from "react";
import {
  Link,
  AppBar as MuiAppBar,
  Toolbar,
  Hidden,
  Grid,
  Slide,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "styled-components/macro";
import { Link as ReactRouterDOMLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AppBarMenuTextLink from "./AppBarMenuTextLink";
import RoundedCornerButton from "../components/RoundedCornerButton";
import { SiteMenuType } from "../types/site";
import { AppStateType } from "../redux/reducers";
import { signOut } from "../redux/actions/authActions";
import {
  HOME_PAGE_URL,
  ADMIN_HOME_URL,
  SIGN_IN_PAGE_URL,
} from "../constants/urls";

const AppBar = styled(MuiAppBar)<{ height: number }>`
  height: ${(props) => props.height}px;
  background-color: white;
  box-shadow: 0 4px 12px 0 rgba(18, 38, 63, 0.125);
  color: ${(props) => props.theme.palette.text.primary};
`;

const ToolbarMobile = styled(Toolbar)`
  justify-content: space-between;
`;

interface HideOnScrollProps {
  children: React.ReactElement;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type PublicPageAppBarPropsType = {
  toggleDrawer: (
    open: boolean
  ) => (
    event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => void;
  appBarHeight: number;
  siteMenu: SiteMenuType;
};

const PublicPageAppBar: React.FC<PublicPageAppBarPropsType> = (props) => {
  const { toggleDrawer, appBarHeight } = props;

  const dispatch = useDispatch();
  const auth = useSelector((state: AppStateType) => state.authReducer);

  const menuItems = () => {
    const menuItemsArray: JSX.Element[] = [];

    props.siteMenu.menuItems.forEach((menuItem, index) => {
      const siteMenuItem = (
        <AppBarMenuTextLink
          to={menuItem.path}
          text={menuItem.title.toUpperCase()}
          key={index}
        />
      );
      menuItemsArray.push(siteMenuItem);
    });

    if (auth.loggedInUser) {
      if (auth.loggedInUser?.isAdmin) {
        const adminButton = (
          <AppBarMenuTextLink to={ADMIN_HOME_URL} text="ADMIN" key="admin" />
        );
        menuItemsArray.push(adminButton);
      }

      const signOutButton = (
        <RoundedCornerButton
          key="sign_out"
          variant="menuItem"
          color="secondary"
          onClick={async () => {
            await dispatch(signOut());
          }}
          size="large"
        >
          SIGN OUT
        </RoundedCornerButton>
      );
      menuItemsArray.push(signOutButton);
    } else {
      const signInButton = (
        <Link
          component={ReactRouterDOMLink}
          to={SIGN_IN_PAGE_URL}
          key="sign_in"
          underline="none"
        >
          <RoundedCornerButton
            variant="menuItem"
            color="secondary"
            size="large"
          >
            SIGN IN
          </RoundedCornerButton>
        </Link>
      );
      menuItemsArray.push(signInButton);
    }

    return menuItemsArray;
  };

  return (
    <HideOnScroll {...props}>
      <AppBar height={appBarHeight}>
        <Hidden mdUp>
          <ToolbarMobile>
            <Link component={ReactRouterDOMLink} to={HOME_PAGE_URL}>
              <img
                src="/static/img/in_contact_light_bg_logo.jpg"
                alt="InContact"
                height={appBarHeight - 5}
                style={{
                  paddingTop: 5,
                }}
              />
            </Link>
            <IconButton color="primary" onClick={toggleDrawer(true)} edge="end">
              <MenuIcon fontSize="large" />
            </IconButton>
          </ToolbarMobile>
        </Hidden>
        <Hidden smDown>
          <Toolbar>
            <Grid container>
              <Grid item md={1} />
              <Grid
                item
                container
                md={10}
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Link component={ReactRouterDOMLink} to={HOME_PAGE_URL}>
                    <img
                      src="/static/img/in_contact_light_bg_logo.jpg"
                      alt="InContact"
                      height={appBarHeight - 5}
                      style={{
                        paddingTop: 5,
                      }}
                    />
                  </Link>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    columnGap: 30,
                    alignItems: "center",
                  }}
                >
                  {menuItems()}
                </Grid>
              </Grid>
              <Grid item md={1} />
            </Grid>
          </Toolbar>
        </Hidden>
      </AppBar>
    </HideOnScroll>
  );
};

export default PublicPageAppBar;
