import React from "react";
import styled from "styled-components/macro";
import { Box, Grid } from "@material-ui/core";
import PublicPageTypography from "./PublicPageTypography";
import { spacing } from "@material-ui/system";
import TextLink, { TextLinkProps } from "./TextLink";
import {
  INCONTACT_FACEBOOK_URL,
  INCONTACT_INSTAGRAM_URL,
  INCONTACT_YOUTUBE_URL,
  INCONTACT_TELEGRAM_URL,
  ABOUT_US_PAGE_URL,
  CONTACT_US_PAGE_URL,
  PRIVACY_PAGE_URL,
  TERMS_AND_CONDITIONS_PAGE_URL,
} from "../constants/urls";

import {
  CONTACT_US_EMAIL,
  CONTACT_US_PHONE_NUMBER,
} from "../constants/miscellaneous";

const PublicPageTypographyWithSpacing = styled(PublicPageTypography)(spacing);

const PublicPageFooterWrapper = styled(Grid)`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #0a0a14;
  color: white;
  flex: none;
`;

const GridWrapper = styled(Grid)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: 20px 0px;
    flex-wrap: wrap;
  }
`;

const ColumnWithTitleAndLinksWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const GridWithOrder = styled(Grid)<{
  $orderForMobileSite?: number;
  $orderForDesktopSite?: number;
}>`
  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-top: 5px;
    margin-bottom: 5px;
    order: ${(props) =>
      props.$orderForMobileSite ? props.$orderForMobileSite : 0};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    margin-top: 0px;
    margin-bottom: 0px;
    order: ${(props) =>
      props.$orderForDesktopSite ? props.$orderForDesktopSite : 0};
  }
`;

interface LinkType {
  linkTitle: string;
  linkUrl: string;
  type: TextLinkProps["type"];
}

const SITE_MAP_LINKS: LinkType[] = [
  { linkTitle: "About Us", linkUrl: ABOUT_US_PAGE_URL, type: "RouterLink" },
  { linkTitle: "Contact Us", linkUrl: CONTACT_US_PAGE_URL, type: "RouterLink" },
];

const USEFUL_LINKS: LinkType[] = [
  {
    linkTitle: "Privacy & Cookies",
    linkUrl: PRIVACY_PAGE_URL,
    type: "RouterLink",
  },
  {
    linkTitle: "Terms & Conditions",
    linkUrl: TERMS_AND_CONDITIONS_PAGE_URL,
    type: "RouterLink",
  },
];

const SOCIAL_MEDIA_LINKS: LinkType[] = [
  {
    linkTitle: "Instagram",
    linkUrl: INCONTACT_INSTAGRAM_URL,
    type: "AnchorInNewWindow",
  },
  {
    linkTitle: "Facebook",
    linkUrl: INCONTACT_FACEBOOK_URL,
    type: "AnchorInNewWindow",
  },
  {
    linkTitle: "Youtube",
    linkUrl: INCONTACT_YOUTUBE_URL,
    type: "AnchorInNewWindow",
  },
  {
    linkTitle: "Telegram",
    linkUrl: INCONTACT_TELEGRAM_URL,
    type: "AnchorInNewWindow",
  },
];

const LogoAndAddressColumn = () => {
  return (
    <Grid item container xs={12} spacing={4}>
      <Grid item style={{ width: "100%", paddingRight: 20 }}>
        <img
          src="/static/img/in_contact_dark_bg_logo.jpg"
          alt="InContact"
          width="100%"
          style={{ maxWidth: 220 }}
        />
      </Grid>
      <Grid item container direction="column" justify="flex-end">
        <TextLink
          text={CONTACT_US_PHONE_NUMBER}
          to={`tel:${CONTACT_US_PHONE_NUMBER}`}
          type="AnchorInSameWindow"
          fontSize={15}
        />
        <TextLink
          text={CONTACT_US_EMAIL}
          to={`mailto:${CONTACT_US_EMAIL}`}
          type="AnchorInSameWindow"
          fontSize={15}
        />
      </Grid>
    </Grid>
  );
};

interface ColumnWithTitleAndLinksProps {
  title: string;
  links: LinkType[];
}

const ColumnWithTitleAndLinks: React.FC<ColumnWithTitleAndLinksProps> = ({
  title,
  links,
}) => {
  return (
    <ColumnWithTitleAndLinksWrapper>
      <PublicPageTypographyWithSpacing
        $sizeInPixels={16}
        pb={2}
        style={{ color: "#676a73" }}
      >
        <b>{title}</b>
      </PublicPageTypographyWithSpacing>
      {links.map((link) => (
        <TextLink
          text={link.linkTitle}
          to={link.linkUrl}
          key={link.linkTitle}
          type={link.type}
        />
      ))}
    </ColumnWithTitleAndLinksWrapper>
  );
};

const PublicPageFooter = () => {
  return (
    <PublicPageFooterWrapper item container xs={12}>
      <Grid item xs={1} md={2}></Grid>
      <GridWrapper item container xs={10} md={8}>
        <GridWithOrder
          item
          xs={6}
          md={4}
          $orderForDesktopSite={1}
          $orderForMobileSite={3}
        >
          <LogoAndAddressColumn />
        </GridWithOrder>
        <GridWithOrder
          item
          xs={6}
          md={2}
          $orderForDesktopSite={2}
          $orderForMobileSite={1}
        >
          <ColumnWithTitleAndLinks title="SITE MAP" links={SITE_MAP_LINKS} />
        </GridWithOrder>
        <GridWithOrder
          item
          xs={6}
          md={3}
          $orderForDesktopSite={3}
          $orderForMobileSite={4}
        >
          <ColumnWithTitleAndLinks title="USEFUL LINKS" links={USEFUL_LINKS} />
        </GridWithOrder>
        <GridWithOrder
          item
          xs={6}
          md={3}
          $orderForDesktopSite={4}
          $orderForMobileSite={2}
        >
          <ColumnWithTitleAndLinks
            title="SOCIAL MEDIA"
            links={SOCIAL_MEDIA_LINKS}
          />
        </GridWithOrder>
      </GridWrapper>
      <Grid item xs={1} md={2}></Grid>
    </PublicPageFooterWrapper>
  );
};

export default PublicPageFooter;
