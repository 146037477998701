import styled from "styled-components/macro";
import { Typography } from "@material-ui/core";

const PublicPageTypography = styled(Typography)<{
  $sizeInPixels?: number;
  $lineHeight?: number;
  $fontWeight?: "normal" | "bold" | "200" | "900";
  $fontStyle?: "normal" | "italic";
}>`
  font-size: ${(props) => props.$sizeInPixels || "13"}px;
  font-weight: ${(props) => props.$fontWeight || "normal"};
  line-height: ${(props) => props.$lineHeight || "normal"};
  font-style: ${(props) => props.$fontStyle || "normal"};
`;

export default PublicPageTypography;
