import React, { useState } from "react";
import styled, {
  createGlobalStyle,
  ThemeProvider,
} from "styled-components/macro";
import { GlobalStyleProps } from "../types/styles";
import { RouteType } from "../types/routes";

import { CssBaseline, MuiThemeProvider, Hidden } from "@material-ui/core";

import PublicPageAppBar from "../components/PublicPageAppBar";
import PublicPageDrawer from "../components/PublicPageDrawer";
import PublicPageFooter from "../components/PublicPageFooter";
import { THEMES } from "../constants";
import createTheme from "../theme";
import { publicPageSiteMenu } from "../utils/utilities";

const APP_BAR_HEIGHT = 70;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

type PublicPagePropsType = {
  routes: Array<RouteType>;
};

const PublicPage: React.FC<PublicPagePropsType> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const siteMenu = publicPageSiteMenu();

  return (
    <MuiThemeProvider theme={createTheme(THEMES.PUBLIC_PAGES)}>
      <ThemeProvider theme={createTheme(THEMES.PUBLIC_PAGES)}>
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <Hidden xlUp>
            <PublicPageDrawer
              siteMenu={siteMenu}
              open={isDrawerOpen}
              toggleDrawer={toggleDrawer}
            />
          </Hidden>

          <AppContent>
            <PublicPageAppBar
              toggleDrawer={toggleDrawer}
              appBarHeight={APP_BAR_HEIGHT}
              siteMenu={siteMenu}
            />
            <div
              style={{ paddingTop: APP_BAR_HEIGHT, display: "flex", flex: 1 }}
            >
              {children}
            </div>
            <PublicPageFooter />
          </AppContent>
        </Root>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default PublicPage;
