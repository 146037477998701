import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Drawer as MUIDrawer,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import CloseIcon from "@material-ui/icons/Close";

import { SiteMenuType } from "../types/site";
import { AppStateType } from "../redux/reducers";
import { signOut } from "../redux/actions/authActions";
import SocialMedia from "./SocialMedia";
import { ADMIN_HOME_URL, SIGN_IN_PAGE_URL } from "../constants/urls";

const drawerWidth = 260;

const FONT_SIZE_MENU_ITEM_TEXT = 22;
const FONT_WEIGHT_MENU_ITEM_TEXT = "normal";

const HeaderWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding-right: ${(props) => props.theme.spacing(1)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
`;

const BodyWrapper = styled(Box)`
  flex: 1;
`;

const FooterWrapper = styled(Box)`
  padding: 5px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    drawerTitleText: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    listItemTextPrimary: {
      fontWeight: "bold",
      fontSize: 17,
    },
  })
);

interface DrawerHeaderProps {
  closeButtonHandler: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ closeButtonHandler }) => {
  return (
    <HeaderWrapper>
      <IconButton onClick={closeButtonHandler}>
        <CloseIcon fontSize="large" color="primary" />
      </IconButton>
    </HeaderWrapper>
  );
};

interface DrawerBodyProps {
  siteMenu: SiteMenuType;
}

const DrawerBody: React.FC<DrawerBodyProps> = ({ siteMenu }) => {
  const history = useHistory();
  const auth = useSelector((state: AppStateType) => state.authReducer);
  const dispatch = useDispatch();

  const listItem = (
    path: string,
    title: string,
    key: number | string,
    color: "primary" | "secondary" = "primary"
  ) => {
    return (
      <ListItem
        button
        key={key}
        onClick={() => {
          history.push(path);
        }}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            style: {
              fontSize: FONT_SIZE_MENU_ITEM_TEXT,
              fontWeight: FONT_WEIGHT_MENU_ITEM_TEXT,
            },
            color: color,
          }}
        />
      </ListItem>
    );
  };

  const listItems = () => {
    const listItemsArray: JSX.Element[] = [];

    siteMenu.menuItems.forEach((menuItem, index) => {
      listItemsArray.push(listItem(menuItem.path, menuItem.title, index));
    });

    if (auth.loggedInUser) {
      if (auth.loggedInUser?.isAdmin) {
        listItemsArray.push(listItem(ADMIN_HOME_URL, "Admin", "admin"));
      }
      const signOutListItem = (
        <ListItem
          button
          key="sign_out"
          onClick={async () => {
            await dispatch(signOut());
          }}
        >
          <ListItemText
            primary="Sign Out"
            primaryTypographyProps={{
              style: {
                fontSize: FONT_SIZE_MENU_ITEM_TEXT,
                fontWeight: FONT_WEIGHT_MENU_ITEM_TEXT,
              },
              color: "secondary",
            }}
          />
        </ListItem>
      );
      listItemsArray.push(signOutListItem);
    } else {
      listItemsArray.push(
        listItem(SIGN_IN_PAGE_URL, "Sign In", "sign_in", "secondary")
      );
    }

    return listItemsArray;
  };

  return (
    <BodyWrapper>
      <List>{listItems()}</List>
    </BodyWrapper>
  );
};

const DrawerFooter: React.FC = () => {
  return (
    <FooterWrapper>
      <SocialMedia fontSize={40} />
    </FooterWrapper>
  );
};

type PublicPageDrawerPropsType = {
  open: boolean;
  toggleDrawer: (
    open: boolean
  ) => (
    event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => void;

  siteMenu: SiteMenuType;
};

const PublicPageDrawer: React.FC<PublicPageDrawerPropsType> = ({
  open,
  toggleDrawer,
  siteMenu,
}) => {
  const classes = useStyles();

  return (
    <div>
      <MUIDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        variant="temporary"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <DrawerHeader closeButtonHandler={toggleDrawer(false)} />
        <DrawerBody siteMenu={siteMenu} />
        <DrawerFooter />
      </MUIDrawer>
    </div>
  );
};

export default PublicPageDrawer;
