import { SiteMenuType } from "../types/site";
import DisplayStringsMap from "../constants/display-strings";

import { PUBLIC_PAGE_SITE_NAVIGATION_FIXED_MENU_ITEMS } from "../constants/index";

const publicPageSiteMenu = (): SiteMenuType => {
  const siteMenu: SiteMenuType = {
    menuItems: PUBLIC_PAGE_SITE_NAVIGATION_FIXED_MENU_ITEMS.map(
      ({ title, path }) => {
        return { title, path };
      }
    ),
  };

  return siteMenu;
};

const displayStringFor = (value: string | null | undefined): string => {
  if (!value) return "";

  const displayString = DisplayStringsMap.get(value);
  if (!displayString) return "";

  return displayString;
};

export { displayStringFor, publicPageSiteMenu };
