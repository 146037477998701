import firebase from "firebase";

import axios from "../utils/axios";
import Firebase from "./firebase";
import { ResetPasswordType, SignInType, SignUpType } from "../types/auth";

export function signUp(credentials: SignUpType) {
  return new Promise<boolean>((resolve, reject) => {
    Firebase.getInstance()
      .signUpUserWithEmailAndPassword(credentials.email, credentials.password)
      .then((authUser) => {
        if (authUser.user) {
          // Create user profile in the database.
          return Firebase.getInstance().createUserInDatabase(
            authUser.user.uid,
            credentials.name,
            credentials.email
          );
        } else {
          throw new Error(
            "Unexpected error while signing up. Please try again or contact us."
          );
        }
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function signIn(credentials: SignInType, withPersistence: boolean) {
  return new Promise<firebase.auth.UserCredential>((resolve, reject) => {
    Firebase.getInstance()
      .signInWithEmailAndPassword(
        credentials.email,
        credentials.password,
        withPersistence
      )
      .then((authUser) => {
        resolve(authUser);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signOut() {
  return new Promise<void>((resolve, reject) => {
    Firebase.getInstance()
      .signOut()
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(email: string) {
  return new Promise<void>((resolve, reject) => {
    Firebase.getInstance()
      .sendPasswordResetEmail(email)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
