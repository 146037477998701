import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import Firebase from "./services/firebase";
import { fetchUser } from "./services/usersService";
import {
  userSignInStarted,
  userSignInFinished,
  userSignInFailed,
} from "./redux/actions/authActions";
import Loader from "./components/Loader";

interface PersistenceAuthProviderType {
  children: JSX.Element;
}

/*
This idea or method is inspired from the youtube video 'PERSISTENT LOGIN WITH FIREBASE AND REACT'
Link: https://www.youtube.com/watch?v=2Oz-OLB8FQQ
*/

const PersistenceAuthProvider: React.FC<PersistenceAuthProviderType> = ({
  children,
}: PersistenceAuthProviderType) => {
  const dispatch = useDispatch();

  const [
    isFirstAuthStateChangedPending,
    setIsFirstAuthStateChangedPending,
  ] = useState(true);

  useEffect(() => {
    const listener = Firebase.getInstance().onAuthStateChangedListener(
      (authUser) => {
        if (isFirstAuthStateChangedPending) {
          if (authUser) {
            // This means a user sign in has occurred from Firebase Authentication Persistence

            // Update the redux store.
            dispatch(userSignInStarted());

            // Fetch the user profile from the DB
            fetchUser(authUser.uid)
              .then((user) => {
                // Update the redux store.
                dispatch(userSignInFinished(authUser, user));
              })
              .catch((error) => {
                const message = error.message || "Something went wrong";
                // Update the redux store.
                dispatch(userSignInFailed(message));
              })
              .finally(() => {
                setIsFirstAuthStateChangedPending(false);
              });
          } else {
            setIsFirstAuthStateChangedPending(false);
          }
        }
      }
    );

    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFirstAuthStateChangedPending)
    return (
      <Box style={{ minHeight: "100vh", display: "flex" }}>
        <Loader />
      </Box>
    );

  return children;
};

export default PersistenceAuthProvider;
