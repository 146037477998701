import { EventType, EventParticipantType } from "../../types/events";
import * as types from "../../constants";

type EventRegistrationDeskStateType = {
  event?: EventType;
  eventIsLoading?: boolean;
  eventError?: string;
  eventHasPendingWritesToServer?: boolean;
  errorWhileAddingParticipantToEventOnTheServer?: string;
  errorWhileRemovingParticipantFromEventOnTheServer?: string;
};

export type EventsStateType = {
  eventRegistrationDesk?: EventRegistrationDeskStateType;
  adminAllEvents: EventType[];
  adminAllEventsIsLoading: boolean;
  adminAllEventsError: string;
};

export default function reducer(
  state: EventsStateType = {
    adminAllEvents: [],
    adminAllEventsIsLoading: false,
    adminAllEventsError: "",
  },
  actions: {
    type: string;
    eventRegistrationDesk?: {
      event?: EventType;
      eventError?: string;
      participantToBeAdded?: EventParticipantType;
      participantToBeRemoved?: EventParticipantType;
      eventHasPendingWritesToServer?: boolean;
      errorWhileAddingParticipantToEventOnTheServer?: string;
      errorWhileRemovingParticipantFromEventOnTheServer?: string;
    };
    events?: EventType[];
    error?: string;
    eventIdToBeDeleted?: string;
    eventIdToBeUpdated?: string;
    eventDataToBeUpdated?: { eventPosterUrl?: string };
    event?: EventType;
  }
): EventsStateType {
  switch (actions.type) {
    case types.EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_REQUEST: {
      return {
        ...state,
        eventRegistrationDesk: {
          ...state.eventRegistrationDesk,
          eventIsLoading: true,
          eventError: "",
        },
      };
    }

    case types.EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_SUCCESS: {
      return {
        ...state,
        eventRegistrationDesk: {
          ...state.eventRegistrationDesk,
          eventIsLoading: false,
          event: actions.eventRegistrationDesk?.event,
        },
      };
    }

    case types.EVENTS_EVENT_REGISTRATION_DESK_FETCH_EVENT_DETAILS_FAILURE: {
      return {
        ...state,
        eventRegistrationDesk: {
          ...state.eventRegistrationDesk,
          eventIsLoading: false,
          eventError: actions.eventRegistrationDesk?.eventError,
        },
      };
    }

    case types.EVENTS_EVENT_REGISTRATION_DESK_ADD_PARTICIPANT_SUCCESS: {
      const event = state.eventRegistrationDesk?.event;
      const participantToBeAdded =
        actions.eventRegistrationDesk?.participantToBeAdded;
      console.log(event, participantToBeAdded);
      if (event && participantToBeAdded) {
        event.participants.push(participantToBeAdded);
        return {
          ...state,
          eventRegistrationDesk: {
            ...state.eventRegistrationDesk,
            event: event,
          },
        };
      } else {
        return state;
      }
    }

    case types.EVENTS_EVENT_REGISTRATION_DESK_REMOVE_PARTICIPANT_SUCCESS: {
      const event = state.eventRegistrationDesk?.event;
      const participantToBeRemoved =
        actions.eventRegistrationDesk?.participantToBeRemoved;
      console.log(event, participantToBeRemoved);
      if (event && participantToBeRemoved) {
        const indexOfParticipantToBeRemoved = event.participants.indexOf(
          participantToBeRemoved
        );
        if (indexOfParticipantToBeRemoved >= 0) {
          event.participants.splice(indexOfParticipantToBeRemoved, 1);
        }

        return {
          ...state,
          eventRegistrationDesk: {
            ...state.eventRegistrationDesk,
            event: event,
          },
        };
      } else {
        return state;
      }
    }

    case types.EVENTS_EVENT_REGISTRATION_DESK_EVENT_HAS_PENDING_WRITES_UPDATE: {
      return {
        ...state,
        eventRegistrationDesk: {
          ...state.eventRegistrationDesk,
          eventHasPendingWritesToServer:
            actions.eventRegistrationDesk?.eventHasPendingWritesToServer,
        },
      };
    }

    case types.EVENTS_EVENT_REGISTRATION_DESK_ERROR_ADDING_PARTICIPANT_TO_EVENT_ON_SERVER: {
      return {
        ...state,
        eventRegistrationDesk: {
          ...state.eventRegistrationDesk,
          errorWhileAddingParticipantToEventOnTheServer:
            actions.eventRegistrationDesk
              ?.errorWhileAddingParticipantToEventOnTheServer,
        },
      };
    }

    case types.EVENTS_EVENT_REGISTRATION_DESK_ERROR_REMOVING_PARTICIPANT_FROM_EVENT_ON_SERVER: {
      return {
        ...state,
        eventRegistrationDesk: {
          ...state.eventRegistrationDesk,
          errorWhileRemovingParticipantFromEventOnTheServer:
            actions.eventRegistrationDesk
              ?.errorWhileRemovingParticipantFromEventOnTheServer,
        },
      };
    }

    case types.EVENTS_ADMIN_FETCH_ALL_EVENTS_REQUEST: {
      return { ...state, adminAllEventsIsLoading: true };
    }

    case types.EVENTS_ADMIN_FETCH_ALL_EVENTS_SUCCESS: {
      return {
        ...state,
        adminAllEventsIsLoading: false,
        adminAllEventsError: "",
        adminAllEvents: actions.events ? actions.events : [],
      };
    }

    case types.EVENTS_ADMIN_FETCH_ALL_EVENTS_FAILURE: {
      return {
        ...state,
        adminAllEventsIsLoading: false,
        adminAllEventsError: actions.error
          ? actions.error
          : "Something went wrong while fetching events.",
      };
    }

    case types.EVENTS_ADMIN_ALL_EVENTS_DELETE_EVENT: {
      return {
        ...state,
        adminAllEvents: state.adminAllEvents.filter(
          (el) => el.id !== actions.eventIdToBeDeleted
        ),
      };
    }

    case types.EVENTS_ADMIN_UPDATE_EVENT_POSTER_URL: {
      const getUpdatedEventObject = (
        oldEventObject: EventType,
        updatedEventPosterUrl?: string
      ): EventType => {
        return {
          ...oldEventObject,
          eventPosterUrl: updatedEventPosterUrl ? updatedEventPosterUrl : "",
        };
      };

      return {
        ...state,
        adminAllEvents: state.adminAllEvents.map((el) =>
          el.id !== actions.eventIdToBeUpdated
            ? el
            : getUpdatedEventObject(
                el,
                actions.eventDataToBeUpdated?.eventPosterUrl
              )
        ),
      };
    }

    default:
      return state;
  }
}
