import * as types from "../../constants";

export function persistenceEnableSucceeded() {
  return { type: types.GENERAL_PERSISTENCE_ENABLE_SUCCESS };
}

export function persistenceEnableFailed(errorCode: string) {
  return {
    type: types.GENERAL_PERSISTENCE_ENABLE_FAILURE,
    errorCodeWhileEnablingPersistence: errorCode,
  };
}
