const userList = [
  {
    name: "Harman",
    type: "admin",
    permissions: ["create", "edit", "view"],
    email: "harman@demo.com",
    address: {
      addressLine1: "House No-123",
      addressLine2: "Near New Street",
      pincode: "110092",
      state: "New Delhi",
      country: "India",
    },
    contactNo: "9999999999",
  },
  {
    name: "Karan",
    type: "endUser",
    permissions: ["view"],
    email: "karan@demo.com",
    address: {
      addressLine1: "House No-456",
      addressLine2: "Near Old Street",
      pincode: "110031",
      state: "New Delhi",
      country: "India",
    },
    contactNo: "1111111111",
  },
];

export default userList;
