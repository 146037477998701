import React from "react";
import styled from "styled-components/macro";
import { Button as MuiButton, ButtonProps } from "@material-ui/core";

const Button = styled(MuiButton)<{
  $variantStyle: ButtonProps["variant"] | "menuItem";
}>`
  padding-top: 10px;

  ${(props) =>
    props.$variantStyle === "outlined"
      ? `height: 53px; 
      border-radius: 30px; 
      font-size: 16px; 
      font-weight: bold; 
      padding-left: 43px;
      padding-right: 43px;
      &:hover {
        color: ${props.theme.palette.secondary.contrastText};
        background-color: ${props.theme.palette.secondary.main};
      }
      `
      : ""}
  ${(props) =>
    props.$variantStyle === "contained"
      ? `height: 53px;
      border-radius: 30px;
      font-size: 16px;
      font-weight: bold;
      padding-left: 43px;
      padding-right: 43px;
          `
      : ""}
    ${(props) =>
    props.$variantStyle === "menuItem"
      ? `height: 36px; 
      border-radius: 18px; 
      font-size: 13px; 
      font-weight: bold; 
      letter-spacing: 1px;
      padding-left: 13px;
      padding-right: 13px;
      `
      : ""};
`;
interface RoundedCornerButtonProps {
  children: React.ReactNode;
  variant?: ButtonProps["variant"] | "menuItem";
  color?: ButtonProps["color"];
  onClick?: ButtonProps["onClick"];
  style?: ButtonProps["style"];
  size?: ButtonProps["size"];
}

const RoundedCornerButton: React.FC<RoundedCornerButtonProps> = ({
  children,
  variant,
  ...rest
}) => {
  return variant === "outlined" ||
    variant === "contained" ||
    variant === "text" ? (
    <Button variant={variant} $variantStyle={variant} {...rest}>
      {children}
    </Button>
  ) : (
    <Button variant="contained" $variantStyle={variant} {...rest}>
      {children}
    </Button>
  );
};

export default RoundedCornerButton;
