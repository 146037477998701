import mock from "../utils/mock";
import userList from "../constants/userlist";

const userData = {
  id: "12345",
  email: "demo@bootlab.io",
  name: "Lucy Lavender",
};

mock.onPost("/api/auth/sign-in").reply((config) => {
  const { email, password } = JSON.parse(config.data);
  const user = userList.find((user) => user.email === email);
  if (user) {
    return [200, user];
  }

  return [401, { message: "Please check your email and password" }];
});

mock.onPost("/api/auth/sign-up").reply(() => {
  return [200, userData];
});

mock.onPost("/api/auth/reset-password").reply(() => {
  return [200, userData];
});

mock.onGet("/users").reply(() => {
  return [200, null];
});

mock.onPost("/users").reply(() => {
  return [200, null];
});

mock.onPut("/users").reply(() => {
  return [200, null];
});
