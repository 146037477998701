import React from "react";
import { Link, LinkProps } from "@material-ui/core";
import { Link as ReactRouterDOMLink } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles<Theme, TextLinkProps>((theme: Theme) =>
  createStyles({
    link: {
      fontWeight: (props) => (props.fontWeight ? props.fontWeight : "normal"),
      fontSize: (props) => (props.fontSize ? props.fontSize : 13),
      letterSpacing: (props) =>
        props.letterSpacing ? props.letterSpacing : "normal",
      "&:hover": {
        color: theme.palette.text.secondary,
      },
    },
  })
);

export interface TextLinkProps {
  to: string;
  text: string;
  fontWeight?: "normal" | "bold";
  fontSize?: number;
  letterSpacing?: number;
  type?: "RouterLink" | "AnchorInSameWindow" | "AnchorInNewWindow";
  color?: LinkProps["color"];
}

const TextLink: React.FC<TextLinkProps> = (props) => {
  const { to, text, type = "RouterLink", color } = props;
  const classes = useStyles(props);

  return (
    <Link
      component={type === "RouterLink" ? ReactRouterDOMLink : "a"}
      to={type === "RouterLink" ? to : undefined}
      href={
        type === "AnchorInNewWindow" || type === "AnchorInSameWindow"
          ? to
          : undefined
      }
      className={classes.link}
      underline="none"
      color={color ? color : "inherit"}
      target={type === "AnchorInNewWindow" ? "_blank" : undefined}
      rel={type === "AnchorInNewWindow" ? "noopener" : undefined}
    >
      {text}
    </Link>
  );
};

export default TextLink;
